import { Component } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { BrandingService } from '../../../pages/branding/branding.service';

declare var window: any;

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <span class="created-by">{{domain_footer}}</span>
  <!--
  <div class="socials">
    <a href="https://github.com/ictinnovations/ictfax" target="_blank" class="ion ion-social-github"></a>
    <a href="https://www.facebook.com/ICTInnovations/" target="_blank" class="ion ion-social-facebook"></a>
    <a href="https://twitter.com/tahiralmas" target="_blank" class="ion ion-social-twitter"></a>
    <a href="https://pk.linkedin.com/company/ict-innovations" target="_blank" class="ion ion-social-linkedin"></a>
  </div>
  !-->
  `,
})
export class FooterComponent {

  auser: any;
  
  domain_footer: string = 'Developed by AireSpring';

  constructor(private authService: NbAuthService, private branding_service: BrandingService) {

    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {
      this.auser = token.getPayload();
      this.get_domain_footer(this.auser.tenant_id);
      // const brand:any = this.get_domain_footer(this.auser.tenant_id);
      // if (!brand) this.get_domain_footer(0);
    });
  }
  get_domain_footer(tenant_id) { 
    this.branding_service.get_Branding(tenant_id).then(response => {
      this.domain_footer = response.footer;
    });
  }

}


